import { Injectable } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';

@Injectable({ providedIn: 'root' })
export class ChartsService {

    constructor(
        private Filter: FilterService,
    ) {

    }

    getTypes() {
        return [
            {
                type: 'ordinal',
                name: this.Filter.translate('columnar')
            },
            {
                type: 'stack_area',
                name: this.Filter.translate('planar')
            }
        ];
    };

    getDataSources() {
        return [
            {
                name: this.Filter.translate('report'),
                type: 'report'
            },
            {
                name: this.Filter.translate('object'),
                type: 'object'
            }
        ];
    };

    getStackedChartSubtypes = () => {
        return [
            'Stack Area',
            'Bar'
        ];
    };

}
